import { createSlice } from "@reduxjs/toolkit"
import { saveEncryptedItem, saveItem } from "../../helpers"

const authSlice = createSlice({
    name: "auth",
    initialState: {
        phoneNumber: "",
        countryId: "",
        isLoggedIn: false,
        userInfo: {},
        userRoles: [],
        refreshTokenExpired: false,
    },
    reducers: {
        setPhoneNumber: (state, { payload: { phoneNumber } }) => {
            state.phoneNumber = phoneNumber
        },
        setcountryId: (state, { payload: { countryId } }) => {
            state.countryId = countryId
            saveEncryptedItem("countryId", countryId)
        },
        setUserData: (state, { payload: { userInfo, userRoles } }) => {
            state.isLoggedIn = true
            saveItem("isUserLoggedIn", true)
            state.userInfo = userInfo
            saveEncryptedItem("userInfo", userInfo)
            state.userRoles = userRoles
            saveEncryptedItem("userRoles", userRoles)
        },
        setAuthData: (state, { payload: { userInfo, userRoles, isLoggedIn } }) => {
            state.isLoggedIn = isLoggedIn
            state.userInfo = userInfo
            state.userRoles = userRoles
        },
        logOut: state => {},
        updateUserName: (state, { payload: { userName } }) => {
            state.userInfo.fullname = userName
        },
        updateUserInfo: (state, { payload: { userInfo } }) => {
            state.userInfo = userInfo
            saveEncryptedItem("userInfo", userInfo)
        },
        setRefreshTokenExpired: (state, { payload }) => {
            state.refreshTokenExpired = payload
        },
    },
})

export const {
    setUserData,
    setPhoneNumber,
    logOut,
    setAuthData,
    updateUserName,
    setcountryId,
    updateUserInfo,
    setRefreshTokenExpired,
} = authSlice.actions
export default authSlice.reducer
