import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getDecryptedItem } from "../helpers"
import { updateUserInfo, setRefreshTokenExpired } from "../modules/landing/authSlice"
import { setAlertFunc } from "../components/Toaster/toasterSlice"

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const userInfo = getDecryptedItem("userInfo")
        const token = userInfo?.token
        const currCountryId = userInfo?.country_id ?? getDecryptedItem("countryId")

        if (currCountryId) headers.set("country-id", currCountryId)
        if (token) headers.set("x-stq-apiKey", `${token}`)

        return headers
    },
})

let isRefreshing = false
let refreshPromise = null

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        if (result.error?.data?.message === "Token is invalid!") {
            if (!isRefreshing) {
                // If not already refreshing, start the refresh process
                isRefreshing = true
                refreshPromise = refreshToken(api, extraOptions)
            }

            // Wait for the refreshPromise to resolve before retrying the initial query
            try {
                const refreshResult = await refreshPromise
                // Retry the initial query if refresh is successful
                if (refreshResult?.data) {
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    // Logout when refresh token fails
                    api.dispatch(setRefreshTokenExpired(true))
                }
            } catch (error) {
                console.error("Failed to refresh token.", error)
            } finally {
                isRefreshing = false
                refreshPromise = null
            }
        } else {
            api.dispatch(
                setAlertFunc({
                    type: "error",
                    message:
                        result.error?.data?.message || result.error?.data?.meta?.message || result.error?.data?.msg,
                })
            )
        }
    }

    return result
}

const refreshToken = async (api, extraOptions) => {
    try {
        // Make API call to refresh the token
        const userData = getDecryptedItem("userInfo")
        const refreshToken = userData?.refresh_token
        const userId = userData?.id

        const refreshResult = await baseQuery(
            { url: "signin/generate_new_tokens", params: { id: userId }, headers: { "Refresh-Token": refreshToken } },
            api,
            extraOptions
        )

        if (refreshResult.data) {
            // Update access token in userInfo
            const newUserInfo = { ...userData }
            newUserInfo.token = refreshResult.data.token.access_token
            api.dispatch(updateUserInfo({ userInfo: newUserInfo }))
        }
        return refreshResult
    } catch (error) {
        console.error("Token refresh failed:", error)
        return error
    }
}

export const mainSplitApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    tagTypes: ["State", "City", "token", "registered", "metric", "details", "auth", "mapped", "admin"],
})
