import { createSlice } from "@reduxjs/toolkit"
import { initialMonthData, initialRangeValue } from "../../../../helpers"

export const initialCostValues = {
    lt: "",
    gt: "",
    bw: { min: "", max: "" },
}

const initialModalFilterData = {
    open: {
        infraCost: false,
        host: false,
        investor: false,
        bep: false,
        onboardingTimeStamp: false,
        commissioningTimeStamp: false,
        station: false,
    },
    values: {
        costType: "",
        costTypeValues: initialCostValues,
        host: null,
        searchHost: "",
        investor: null,
        searchInvestor: "",
        startDateBEP: initialMonthData,
        endDateBEP: initialMonthData,
        onboardingTimeStamp: initialRangeValue,
        commissioningTimeStamp: initialRangeValue,
        station: null,
        searchStation: "",
    },
}

export const initialFilterData = {
    modalFilter: initialModalFilterData,
    quickFilter: {
        search: "",
        startMonth: initialMonthData,
        endMonth: initialMonthData,
        cinNumber: "",
        siteId: "",
    },
}

const chargerPnlSlice = createSlice({
    name: "chargerPnl",
    initialState: {
        activeFilters: initialFilterData,
        selectedTab: "",
        showColumns: [],
    },
    reducers: {
        setActiveFilters: (state, { payload }) => {
            state.activeFilters = payload
        },
        setSelectedTab: (state, { payload }) => {
            state.selectedTab = payload
        },
        setShowColumns: (state, { payload: { columns } }) => {
            state.showColumns = columns
        },
    },
})

export const { setActiveFilters, setSelectedTab, setShowColumns } = chargerPnlSlice.actions

export default chargerPnlSlice.reducer
